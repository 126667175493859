document.addEventListener('DOMContentLoaded', function () {
    console.log('nav menu loaded');

    const sections = document.querySelectorAll('section[id]');

    window.addEventListener("scroll", navHighlighter);

    function navHighlighter() {
        let scrollY = window.pageYOffset;
        sections.forEach(current => {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - window.innerHeight / 2;
            let sectionId = current.getAttribute('id');
            if (
                scrollY > sectionTop &&
                scrollY <= sectionTop + sectionHeight
            ) {
                document.querySelector('.nav a[href*=' + sectionId + ']').classList.add('active');
            } else {
                document.querySelector('.nav a[href*=' + sectionId + ']').classList.remove('active');
            }
        });
    }

    navHighlighter();

}, false);
