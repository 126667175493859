import Swiper, {Pagination} from 'swiper';
import 'swiper/css';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import './nav-menu-highlight.js'
import IMask from 'imask';
// import axios from 'axios';

document.addEventListener('DOMContentLoaded', function () {
    console.log('app.js (main) loaded');

    gsap.registerPlugin(ScrollTrigger);

    const navMenu = document.getElementById('nav-icon1');
    const mobileMenu = document.querySelector('.mobile-menu');

    function openNavMenu() {
        navMenu.classList.add('open');
        mobileMenu.classList.add('open');
    }

    function closeNavMenu() {
        navMenu.classList.remove('open');
        mobileMenu.classList.remove('open');
    }

    navMenu.addEventListener('click', function () {
        if (navMenu.classList.contains('open')) {
            closeNavMenu();
        } else {
            openNavMenu();
        }
    })

    window.addEventListener('scroll', function () {
        closeNavMenu();
    });

    const jsShowModal = document.getElementsByClassName('js-modal'),
        jsModal = document.getElementById('modal'),
        jsCloseModal = document.querySelector('.close-modal');
    for (const btn of jsShowModal) {
        btn.addEventListener('click', function () {
            jsModal.style.display = 'block';
        });
    }

    function closeModal() {
        jsModal.style.display = 'none';
    }

    jsCloseModal.addEventListener('click', function () {
        closeModal();
    });

    function removeHash() {
        history.pushState("", document.title, window.location.pathname
            + window.location.search);
    }

    let element = document.querySelector('#formphone');
    let maskOptions = {
        mask: '+{7} (000) 000-00-00'
    };
    let mask = IMask(element, maskOptions);

    const logozzzImg = document.querySelector('.header__logo_img');

    logozzzImg.addEventListener('click', function () {
        removeHash();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        removeHash();
    });

    const header = document.querySelector('.header');

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            header.classList.add('scrolled-header');
        } else {
            header.classList.remove('scrolled-header');
        }
    }

    window.addEventListener('scroll', function () {
        scrollFunction()
    });

    scrollFunction();

    document
        .querySelectorAll('.nav__item a[href^="#"]')
        .forEach(trigger => {
            trigger.onclick = function (e) {
                e.preventDefault();
                let hash = this.getAttribute('href');
                let target = document.querySelector(hash);
                let headerHeight = document.querySelector('.header').clientHeight;
                let headerOffset = headerHeight + 40;
                let elementPosition = target.offsetTop;
                let offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
                removeHash();
            };
        });

    const swiperCert = new Swiper('#cert-slider', {
        modules: [Pagination],
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        autoplay: {
            delay: 3000,
        },
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        breakpoints: {
            768: {
                autoHeight: false,
            }
        }
    });

    const swiperExamples = new Swiper('#swiper-examples', {
        modules: [Pagination],
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        breakpoints: {
            768: {
                autoHeight: false,
            }
        }
    });

    const swiperReviews = new Swiper('#swiper-reviews', {
        modules: [Pagination],
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        breakpoints: {
            768: {
                autoHeight: false,
            }
        }
    });

    window.addEventListener('resize', function () {
        console.log('resized');
        swiperCert.update();
        swiperExamples.update();
        swiperReviews.update();
    });

    const modalForm = document.getElementById('modalForm'),
        formName = document.getElementById('formname'),
        formPhone = document.getElementById('formphone'),
        modalSubmitBtn = document.getElementById('modalSubmitBtn'),
        modalSuccessMsg = document.getElementById('modalSuccessMsg');

    /* mailer */
    modalForm.addEventListener('submit', function (e) {
        e.preventDefault();
        let fields = {formphone: formPhone.value, formname: formName.value};
        let userPhone = fields.formphone;
        if (userPhone.length === 18) {
            modalSubmitBtn.disabled = true;
            modalSubmitBtn.innerHTML = 'Отправляем...';
            /* Почему-то не удалось подключить axios через импорт, поэтому axios подключен через cdn в хэдере страницы */
            axios({
                method: "post",
                url: "/mailer.php",
                data: fields,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    document.getElementById('formphone').remove();
                    formName.remove();
                    modalSubmitBtn.remove();
                    modalSuccessMsg.innerHTML = 'Ваш запрос отправлен. Мы скоро свяжемся с&nbsp;вами!';
                    modalSuccessMsg.classList.remove('errornum');
                    setTimeout(function () {
                        closeModal();
                    }, 3000);
                    //yaCounter49871425.reachGoal('formfilled');
                })
                .catch(function (response) {
                    alert("Ошибка сервера. Позвоните нам по телефону, указанному на сайте.");
                });

        } else {
            console.log('bad number')
            modalSuccessMsg.innerHTML = 'Номер введён неверно.';
            modalSuccessMsg.classList.add('errornum');
            document.getElementById('formphone').classList.add('error');
        }

        return false;
    });

    /* gsap */

    const tl1 = gsap.timeline({
        scrollTrigger: {
            trigger: '.splash-photo'
        }
    });
    const tlserv1 = gsap.timeline({
        scrollTrigger: {
            trigger: '.service-item:nth-child(1)',
        }
    });
    const tlserv2 = gsap.timeline({
        scrollTrigger: {
            trigger: '.service-item:nth-child(2)',
        }
    });
    const tlserv3 = gsap.timeline({
        scrollTrigger: {
            trigger: '.service-item:nth-child(3)',
        }
    });
    const tlserv4 = gsap.timeline({
        scrollTrigger: {
            trigger: '.service-item:nth-child(4)',
        }
    });
    const tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-about__text',
        }
    });
    const tl3 = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-feedback__slider',
        }
    });
    const tl4 = gsap.timeline({
        scrollTrigger: {
            trigger: '.examples-slider',
        }
    });
    const tl5 = gsap.timeline({
        scrollTrigger: {
            trigger: '.contacts',
        }
    });

    tl1
        .fromTo('.splash-photo', {opacity: 0, y: -100}, {opacity: 1, y: 0})
        .fromTo('h1', {opacity: 0, y: -100}, {opacity: 1, y: 0})
        .fromTo('.linear-face', {opacity: 0}, {opacity: 1})
        .fromTo('.section-services .h2before, .section-services .h2after', {scaleY: 0}, {scaleY: 1})

    tlserv1.from('.service-item:nth-child(1)', {x: 100, opacity: 0})
    tlserv2.from('.service-item:nth-child(2)', {x: -100, opacity: 0})
    tlserv3.from('.service-item:nth-child(3)', {x: 100, opacity: 0})
    tlserv4.from('.service-item:nth-child(4)', {x: -100, opacity: 0})

    tl2.fromTo('.section-about .h2before, .section-about .h2after', {scaleY: 0}, {scaleY: 1})
    tl3.fromTo('.section-feedback .h2before, .section-feedback .h2after', {scaleY: 0}, {scaleY: 1})
    tl4.fromTo('.section-examples .h2before, .section-examples .h2after', {scaleY: 0}, {scaleY: 1})
    tl5.fromTo('.section-contacts .h2before, .section-contacts .h2after', {scaleY: 0}, {scaleY: 1})

}, false);
